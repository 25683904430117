import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const DemoImageWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2em;
  height: 400px;
  margin: auto;
  display: none;
  margin-top: 165px;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      left: 62%;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      left: 54%;
    `
  )}
`;

export const DemoImage = () => (
  <DemoImageWrapper>
    <StaticQuery
      query={graphql`
        query DemoImageQuery {
          demoImage: file(relativePath: { eq: "demo-screen@3x.png" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => <Image {...data.demoImage.childImageSharp} />}
    />
  </DemoImageWrapper>
);
