import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';

import Container from '@components/layout/Container';
import { DemoForm } from '@components/demo/DemoForm';
import { DemoImage } from '@components/demo/DemoImage';
import { DemoPage } from '@content/types/demo';

const DemoPageWrapper = styled.div`
  margin-bottom: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 120px;
    `
  )}
`;

export interface DemoProps extends DemoPage {
  langcode: string;
  urls: Array<string>;
}

const Demo = (props: DemoProps) => {
  return (
    <Container>
      <DemoPageWrapper>
        <DemoForm {...props} langcode={props.langcode} />
        <DemoImage />
      </DemoPageWrapper>
    </Container>
  );
};

export default Demo;
