import React from 'react';
import Page from '@components/layout/Page';
import Demo from '@components/demo';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import useDemoPageQuery from '@graphql/queries/DemoQuery';
import { PageContext } from '@content/types/pageContext';

interface DemoProps extends LocationDependent {
  pageContext: PageContext;
}

const DemoPage = (props: DemoProps) => {
  const demoData = useDemoPageQuery()[props.pageContext.langcode];

  return (
    <Page>
      <MetaPage title={demoData.seo.title} description={demoData.seo.description} />
      <DataLayer location={props.location} />
      <Demo {...demoData} langcode={props.pageContext.langcode} urls={props.pageContext.allUrls} />
    </Page>
  );
};

export default DemoPage;
