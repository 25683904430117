import get from 'lodash.get';

export const demoPageTransformers = page => {
  const demoPageLanguages = {};

  page.allSiteSettingEntityDemo.edges.forEach(edge => {
    const data = edge.node;
    const administration = page.allSiteSettingEntityAdministration.edges[0].node;

    demoPageLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
      },
      form: {
        title: get(data, 'field_settings_text_1', ''),
        subtitle: get(data, 'field_settings_text_2', ''),
        label1: get(data, 'field_settings_text_3', ''),
        label2: get(data, 'field_settings_text_4', ''),
        error1: get(data, 'field_settings_text_5', ''),
        error2: get(data, 'field_settings_text_6', ''),
        checkbox: get(data, 'field_text_1.value', ''),
        modal1: get(data, 'field_text_2.value', ''),
        modal2: get(data, 'field_text_3.value', ''),
        tooltip: get(data, 'field_tooltip', ''),
        button: get(data, 'field_button_text_1', ''),
        recaptcha: get(administration, 'field_recaptcha_key', ''),

        accTitle1: get(data, 'field_agreement_1.value', ''),
        accTitle2: get(data, 'field_agreement_2', ''),
        accContent: get(data, 'field_agreement_3.value', ''),
      },
    };
  });

  return demoPageLanguages;
};
