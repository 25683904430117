import React, { Component } from 'react';

// === Helpers === //
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import * as utils from '@utils';
import * as Yup from 'yup';

// === Config === //
import { colors, respondFrom, breakpoints, css, mixins, dimensions } from '@styles';

// === Components === //
import { Formik, FormikActions, Form } from 'formik';
import { DemoFormInput } from '@components/demo/DemoFormInput';
import { DemoFormCheckbox } from '@components/demo/DemoFormCheckbox';
import Button from '@components/common/Button';
import Tooltip from '@components/common/Tooltip';
import Modal from '@components/common/Modal';
import Collapsible from 'react-collapsible';

// === Types === //
import { DemoPage } from '@content/types/demo';

const DemoFormWrapper = styled.div`
  background-color: ${colors.grayNurse};
  padding: 25px 30px;
  margin-left: -2em;
  width: calc(100% + 4em);

  ${respondFrom(
    breakpoints.md,
    css`
      margin: 0;
      width: 100%;
      max-width: 60vw;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 750px;
      padding: 100px 85px;
    `
  )}

  h3, p {
    max-width: 70vw;

    ${respondFrom(
      breakpoints.md,
      css`
        max-width: none;
      `
    )}

    br {
      display: none;

      ${respondFrom(
        breakpoints.md,
        css`
          display: block;
        `
      )}
    }
  }
`;

const DemoFormComponent = styled.div`
  margin-top: 30px;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 400px;
    `
  )}
`;

const ButtonWrapper = styled.div`
  display: inline-block;
`;

const ReCaptchaWrapper = styled.div`
  height: 110px;
`;

const AccordionWrapper = styled.div<AccordionProps>`
  margin-top: -25px;
  margin-bottom: 30px;
  margin-left: 30px;
  font-size: ${dimensions.fontSize.smaller}px;

  .Collapsible {
    &__trigger {
      position: relative;
      color: ${colors.green.dark};
      cursor: pointer;
      padding-right: 20px;

      &::before {
        content: '${props => props.title1.replace(/(<([^>]+)>)/ig, '')}';
      }

      &::after {
        content: '';
        position: absolute;
        top: -3px;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 7px;
        height: 7px;
        border: 2px solid ${colors.green.dark};
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
        transform-origin: center;
        ${mixins.transitionDefault};
      }

      &.is-open {
        &::before {
          content: '${props => props.title2}';
        }

        &::after {
          transform: translate(0, 3px) rotate(225deg);
        }
      }
    }
  }
`;

export interface DemoFormValues {
  email: string;
  email2: string;
  accept: boolean;
}

interface AccordionProps {
  title1: string;
  title2: string;
}

interface DemoFormProps extends DemoPage {
  langcode: string;
  urls: Array<string>;
}

export class DemoForm extends Component<DemoFormProps> {
  captcha;
  state = {
    accept: false,
    recaptcha: false,
    recaptchaValue: '',
    userExist: false,
    error: false,
  };

  componentDidMount() {
    loadReCaptcha();
  }

  onChangeCheckbox = value => this.setState({ accept: !value });

  onLoadRecaptcha = () => {};

  verifyCallback = response => {
    this.setState({ recaptcha: true, recaptchaValue: response });
  };

  onResetRecaptcha = () => {
    this.captcha.reset();
    this.setState({ recaptcha: false, recaptchaValue: '' });
  };

  render() {
    const { form } = this.props;
    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(form.error1)
        .required(form.error2),
      email2: Yup.string()
        .email(form.error1)
        .oneOf([Yup.ref('email'), null], 'Nieprawidłowy email')
        .required(form.error2),
    });

    return (
      <DemoFormWrapper>
        <h3>{utils.SafeHtml(form.title)}</h3>
        <p>{form.subtitle}</p>

        <DemoFormComponent>
          <Formik
            initialValues={{ email: '', email2: '', accept: false }}
            validationSchema={validationSchema}
            onSubmit={(values: DemoFormValues, actions: FormikActions<DemoFormValues>) => {
              this.setState({ userExist: false, error: false }, () => {
                fetch(`${process.env.IPOS_BE_API_URL}/trial-front`, {
                  method: 'POST',
                  body: JSON.stringify({
                    email: values.email,
                    agreement: values.accept,
                    recaptcha: this.state.recaptchaValue,
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }).then(response => {
                  if (response.status === 204 && typeof window !== typeof undefined) {
                    navigate(utils.langLink(this.props.langcode, this.props.urls[13]));
                  } else if (response.status === 200) {
                    this.setState({ userExist: true });
                  } else {
                    this.setState({ error: true });
                  }
                });
              });

              actions.setSubmitting(false);
            }}
            render={() => (
              <Form autoComplete="on">
                <DemoFormInput
                  name="email"
                  labelText={form.label1}
                  type="email"
                  autocomplete="on"
                  maxlength={100}
                />
                <DemoFormInput
                  name="email2"
                  labelText={form.label2}
                  autocomplete="off"
                  maxlength={100}
                />
                <DemoFormCheckbox
                  name="accept"
                  labelText={form.checkbox}
                  onChangeCheckbox={this.onChangeCheckbox}
                />
                <AccordionWrapper title1={form.accTitle1} title2={form.accTitle2}>
                  <Collapsible trigger="">{utils.SafeHtml(form.accContent)}</Collapsible>
                </AccordionWrapper>

                <ReCaptchaWrapper>
                  <ReCaptcha
                    ref={el => {
                      this.captcha = el;
                    }}
                    render="explicit"
                    sitekey={form.recaptcha}
                    verifyCallback={this.verifyCallback}
                    onloadCallback={this.onLoadRecaptcha}
                    hl={this.props.langcode}
                  />
                </ReCaptchaWrapper>

                <ButtonWrapper data-tip="React-tooltip" data-for="demoButton">
                  <Button
                    icon="arrow-right"
                    type="submit"
                    disabled={!this.state.accept || !this.state.recaptcha ? true : false}
                  >
                    {form.button}
                  </Button>
                </ButtonWrapper>
              </Form>
            )}
          />
        </DemoFormComponent>
        <Modal
          isOpened={this.state.userExist || this.state.error}
          content={this.state.userExist ? form.modal1 : this.state.error ? form.modal2 : ''}
          onCloseModalPanel={this.onResetRecaptcha}
        />
        <Tooltip
          className={this.state.accept && this.state.recaptcha ? 'is-hidden' : ''}
          text={form.tooltip}
          theme="light"
          id="demoButton"
        />
      </DemoFormWrapper>
    );
  }
}
