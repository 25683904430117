import { graphql, useStaticQuery } from 'gatsby';
import * as demoPageTransformers from '@content/transformers/DemoPageTransformers';
import { DemoPage } from '@content/types/demo';

const DemoQuery = (): DemoPage => {
  const demoData = useStaticQuery(graphql`
    query {
      allSiteSettingEntityDemo {
        edges {
          node {
            langcode
            field_button_text_1
            field_meta_description
            field_meta_title
            field_settings_text_1
            field_settings_text_2
            field_settings_text_3
            field_settings_text_4
            field_settings_text_5
            field_settings_text_6
            field_text_1 {
              value
            }
            field_text_2 {
              value
            }
            field_text_3 {
              value
            }
            field_tooltip
            field_agreement_1 {
              value
            }
            field_agreement_2
            field_agreement_3 {
              value
            }
          }
        }
      }
      allSiteSettingEntityAdministration {
        edges {
          node {
            field_recaptcha_key
          }
        }
      }
    }
  `);

  return demoPageTransformers.demoPageTransformers(demoData);
};

export default DemoQuery;
